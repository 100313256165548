.bottom-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: fit-content;
  background-color: var(--body-bg);

  padding: 24px 30px;
}

.bottom-bar .information {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .information-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 120px;

  column-gap: 8px;
}

.information-group-divider {
  width: 1px;
  height: 24px;
  background-color: var(--border-color);
  margin-left: 4px;
  margin-right: 4px;
}

.bottom-bar .information-group:first-child {
  justify-content: flex-start !important;
}

.information-group.w-200 p {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Clips the overflowing text without adding an ellipsis */
  max-width: 200px;
}

.bottom-bar .information-group p {
  margin: 0;
}

.bottom-bar .buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  height: fit-content;
  column-gap: 16px;
}

.super-script {
  position: absolute;
  font-size: 10px;
}
