* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2 {
  font-size: calc(1.3375rem + 1.05vw);
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}

h4 {
  font-size: calc(1.2625rem + 0.15vw);
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 0.9375rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 24px;
  color: var(--heading-color);
}

.text-muted {
  color: var(--text-muted);
}

mark {
  padding: 0.1875em;
  color: var(--highlight-color);
  background-color: var(--highlight-bg);
}

del {
  text-decoration: line-through;
}

u,
ins {
  text-decoration-line: underline;
}

small {
  font-size: 0.875em;
}

b,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

li {
  text-align: match-parent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.list-unstyled {
  list-style-type: none;
  padding-left: 0;

  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-inline-item {
  display: inline-block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--primary-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--secondary-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--success-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--info-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--warning-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--danger-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #fff !important;
  background-color: RGBA(var(--light-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--dark-rgb), var(--bg-opacity, 1)) !important;
}

.badge {
  --badge-padding-x: 10px;
  --badge-padding-y: 5px;
  --badge-font-size: 0.8125rem;
  --badge-font-weight: 600;
  --badge-color: #fff;
  --badge-border-radius: 6px;
  display: inline-block;
  padding: var(--badge-padding-y) var(--badge-padding-x);
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 1;
  color: var(--badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--badge-border-radius);
}

.button-group .btn {
  margin-bottom: 12px;
  margin-right: 12px;
}

.btn:hover {
  color: var(--btn-hover-color);
  background-color: var(--btn-hover-bg);
  border-color: var(--btn-hover-border-color);
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

button:disabled,
button:disabled:hover {
  cursor: default;

  background-color: var(--btn-disabled-bg);
  border-color: var(--btn-disabled-border-color);
  color: var(--btn-disabled-color) !important;
}

.circle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;

  margin-right: 14px;

  padding: 0 !important;
}

.btn,
.circle {
  --btn-padding-x: 16px;
  --btn-padding-y: 9px;
  --btn-font-size: 0.875rem;
  --btn-font-weight: 500;
  --btn-line-height: 1.5;
  --btn-color: var(--body-color);
  --btn-bg: transparent;
  --btn-border-width: var(--border-width);
  --btn-border-color: transparent;
  --btn-border-radius: 8px;
  --btn-hover-border-color: transparent;
  --btn-box-shadow: unset;
  --btn-disabled-opacity: 0.65;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  box-shadow: var(--btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

.btn-primary {
  --btn-bg: #635bff;
  --btn-border-color: #635bff;
  --btn-hover-bg: #5249fe;
  --btn-hover-border-color: #5249fe;
}

.btn-primary,
.circle-primary {
  --btn-outline-color: #635bff;
  --btn-text-outline-color: #635bff;
  --btn-color: #fff;
  --btn-bg: #635bff;
  --btn-border-color: #635bff;
  --btn-hover-color: #fff;
  --btn-hover-bg: #544dd9;
  --btn-hover-border-color: #4f49cc;
  --btn-focus-shadow-rgb: 122, 116, 255;
  --btn-active-color: #fff;
  --btn-active-bg: #4f49cc;
  --btn-active-border-color: #4a44bf;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: var(--primary-bg-subtle);
  --btn-disabled-border-color: var(--primary-border-subtle);
}

.btn-secondary,
.circle-secondary {
  --btn-outline-color: #16cdc7;
  --btn-color: #fff;
  --btn-text-outline-color: #16cdc7;
  --btn-bg: #16cdc7;
  --btn-border-color: #16cdc7;
  --btn-hover-color: #fff;
  --btn-hover-bg: #13aea9;
  --btn-hover-border-color: #12a49f;
  --btn-focus-shadow-rgb: 57, 213, 207;
  --btn-active-color: #fff;
  --btn-active-bg: #12a49f;
  --btn-active-border-color: #119a95;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #16cdc7;
  --btn-disabled-border-color: #16cdc7;
}

.btn-success,
.circle-success {
  --btn-outline-color: #36c76c;
  --btn-text-outline-color: #36c76c;
  --btn-color: #fff;
  --btn-bg: #36c76c;
  --btn-border-color: #36c76c;
  --btn-hover-color: #fff;
  --btn-hover-bg: #2ea95c;
  --btn-hover-border-color: #2b9f56;
  --btn-focus-shadow-rgb: 84, 207, 130;
  --btn-active-color: #fff;
  --btn-active-bg: #2b9f56;
  --btn-active-border-color: #299551;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #b6b6b6;
  --btn-disabled-bg: #36c76c;
  --btn-disabled-border-color: #36c76c;
}

.circle-success-subtle {
  --btn-outline-color: #36c76c;
  --btn-text-outline-color: #36c76c;
  --btn-color: #acd9bd;
  --btn-bg: #ebfaf0;
  --btn-border-color: #acd9bd;
  --btn-hover-color: #36c76c;
  --btn-hover-bg: #ebfaf0;
  --btn-hover-border-color: #36c76c;
  --btn-focus-shadow-rgb: 84, 207, 130;
  --btn-active-color: #36c76c;
  --btn-active-bg: #ebfaf0;
  --btn-active-border-color: #36c76c;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #36c76c;
  --btn-disabled-bg: #ebfaf0;
  --btn-disabled-border-color: #36c76c;
}

.btn-info,
.circle-info {
  --btn-outline-color: #46caeb;
  --btn-text-outline-color: #46caeb;
  --btn-color: #fff;
  --btn-bg: #46caeb;
  --btn-border-color: #46caeb;
  --btn-hover-color: #fff;
  --btn-hover-bg: #3cacc8;
  --btn-hover-border-color: #38a2bc;
  --btn-focus-shadow-rgb: 98, 210, 238;
  --btn-active-color: #fff;
  --btn-active-bg: #38a2bc;
  --btn-active-border-color: #3598b0;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #46caeb;
  --btn-disabled-border-color: #46caeb;
}

.btn-warning,
.circle-warning {
  --btn-outline-color: #f8c20a;
  --btn-text-outline-color: #f8c20a;
  --btn-color: #fff;
  --btn-bg: #f8c20a;
  --btn-border-color: #f8c20a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #d3a509;
  --btn-hover-border-color: #c69b08;
  --btn-focus-shadow-rgb: 249, 203, 47;
  --btn-active-color: #fff;
  --btn-active-bg: #c69b08;
  --btn-active-border-color: #ba9208;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #f8c20a;
  --btn-disabled-border-color: #f8c20a;
}

.btn-danger,
.circle-danger {
  --btn-outline-color: #ff6692;
  --btn-text-outline-color: #ff6692;
  --btn-color: #fff;
  --btn-bg: #ff6692;
  --btn-border-color: #ff6692;
  --btn-hover-color: #fff;
  --btn-hover-bg: #d9577c;
  --btn-hover-border-color: #cc5275;
  --btn-focus-shadow-rgb: 255, 125, 162;
  --btn-active-color: #fff;
  --btn-active-bg: #cc5275;
  --btn-active-border-color: #bf4d6e;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #ff6692;
  --btn-disabled-border-color: #ff6692;
}

.btn-light,
.circle-light {
  --btn-outline-color: var(--light);
  --btn-text-outline-color: var(--light);
  --btn-color: #000;
  --btn-bg: #eff4fa;
  --btn-border-color: #eff4fa;
  --btn-hover-color: #fff;
  --btn-hover-bg: #cbcfd5;
  --btn-hover-border-color: #bfc3c8;
  --btn-focus-shadow-rgb: 203, 207, 213;
  --btn-active-color: #fff;
  --btn-active-bg: #bfc3c8;
  --btn-active-border-color: #b3b7bc;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #b6b6b6;
  --btn-disabled-bg: #eff4fa;
  --btn-disabled-border-color: #eff4fa;
}

.text-light {
  color: var(--light);
}

.btn-dark,
.circle-dark {
  --btn-outline-color: #0a2540;
  --btn-text-outline-color: #0a2540;
  --btn-color: #fff;
  --btn-bg: #0a2540;
  --btn-border-color: #0a2540;
  --btn-hover-color: #fff;
  --btn-hover-bg: #2f465d;
  --btn-hover-border-color: #233b53;
  --btn-focus-shadow-rgb: 47, 70, 93;
  --btn-active-color: #fff;
  --btn-active-bg: #3b5166;
  --btn-active-border-color: #233b53;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #0a2540;
  --btn-disabled-border-color: #0a2540;
}

.btn-outline-dark {
  --btn-color: #0a2540;
  --btn-border-color: #0a2540;
  --btn-hover-color: #fff;
  --btn-hover-bg: #0a2540;
  --btn-hover-border-color: #0a2540;
  --btn-focus-shadow-rgb: 10, 37, 64;
  --btn-active-color: #fff;
  --btn-active-bg: #0a2540;
  --btn-active-border-color: #0a2540;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #0a2540;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #0a2540;
  --gradient: none;
}

.btn-outline-light {
  --btn-color: var(--light);
  --btn-border-color: var(--light);
  --btn-hover-color: #000;
  --btn-hover-bg: var(--light);
  --btn-hover-border-color: var(--light);
  --btn-focus-shadow-rgb: 203, 207, 213;
  --btn-active-color: #000;
  --btn-active-bg: var(--light);
  --btn-active-border-color: var(--light);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: var(--light);
  --btn-disabled-bg: transparent;
}

.btn-outline-success {
  --btn-color: #36c76c;
  --btn-border-color: #36c76c;
  --btn-hover-color: #fff;
  --btn-hover-bg: #36c76c;
  --btn-hover-border-color: #36c76c;
  --btn-focus-shadow-rgb: 54, 199, 108;
  --btn-active-color: #fff;
  --btn-active-bg: #36c76c;
  --btn-active-border-color: #36c76c;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #36c76c;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #36c76c;
}

.text-success {
  color: #36c76c;
}

.round-40 {
  width: 40px !important;
  height: 40px;
}

.round-20 {
  width: 20px !important;
  height: 20px;

  border-radius: 20px !important;
}

.round-30 {
  width: 30px !important;
  height: 30px;

  border-radius: 30px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-circle-outline {
  border-radius: 50% !important;
  background-color: unset !important;
  border: 2px solid var(--btn-outline-color);
  color: var(--btn-text-outline-color) !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.btn-group-sm > .btn,
.btn-sm {
  --btn-padding-y: 0.25rem;
  --btn-padding-x: 0.5rem;
  --btn-font-size: 0.765625rem;
  --btn-border-radius: var(--border-radius-sm);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-left {
  border-left: 1px solid var(--border-color);
}

.list-separator {
  height: 40px;
  width: 2px;
  margin-left: 15px;
  border-radius: 4px;
}

.list-separator[title="success"] {
  background: var(--success);
}

.list-separator[title="success-subtle"] {
  background: var(--success-border-subtle);
}

.list-separator[title="info"] {
  background: var(--info);
}

.list-separator[title="warning"] {
  background: var(--warning);
}

.list-separator[title="danger"] {
  background: var(--danger);
}

.list-separator[title="primary"] {
  background: var(--primary);
}

.list-separator[title="secondary"] {
  background: var(--secondary);
}

.list-separator[title="light"] {
  background: var(--light);
}

.list-separator[title="dark"] {
  background: var(--dark);
}

.bg-primary-subtle {
  background-color: var(--primary-bg-subtle) !important;
}

.text-primary {
  --text-opacity: 1;
  color: var(--primary);
}

.bg-success-subtle {
  background-color: var(--success-bg-subtle) !important;
}

.text-success {
  --text-opacity: 1;
  color: rgba(var(--success-rgb), var(--text-opacity)) !important;
}

.relative {
  position: relative;
}

.disabled-look input {
  background-color: var(--disabled-looking-bg) !important;
  opacity: var(--disabled-looking-opacity) !important;
}

.disabled-look .icon svg {
  fill: var(--primary) !important;
}

.disabled-look .unit {
  color: var(--primary) !important;
}
