:root,
[data-theme=light] {
  --blue: #5d87ff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #ff6692;
  --orange: #fd7e14;
  --yellow: #F8C20A;
  --green: #36c76c;
  --teal: #20c997;
  --cyan: #539bff;
  --black: #000;
  --white: #fff;
  --gray: #29343d;
  --gray-dark: #343a40;
  --gray-100: #f6f7f9;
  --gray-200: #eff4fa;
  --gray-300: #dfe5ef;
  --gray-400: #7c8fac;
  --gray-500: #5a6a85;
  --gray-600: #29343d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --text-muted: #526b7a;
  --primary: #635bff;
  --secondary: #16CDC7;
  --success: #36c76c;
  --info: #468eeb;
  --warning: #F8C20A;
  --danger: #ff6692;
  --error: #ff6692;
  --error-rgb: 255, 102, 146;
  --light: #c6cacf;
  --dark: #0a2540;
  --muted: #526b7a;
  --indigo: #6610f2;
  --light-indigo: #ebf3fe;
  --dark-light: #29343d;
  --light-gray: #F4F7FB;
  --purple: #6f42c1;
  --cyan: #539bff;
  --primary-rgb: 99, 91, 255;
  --secondary-rgb: 22, 205, 199;
  --success-rgb: 54, 199, 108;
  --info-rgb: 70, 202, 235;
  --warning-rgb: 248, 194, 10;
  --danger-rgb: 255, 102, 146;
  --light-rgb: 198, 202, 207;
  --dark-rgb: 10, 37, 64;
  --muted-rgb: 82, 107, 122;
  --indigo-rgb: 102, 16, 242;
  --light-indigo-rgb: 235, 243, 254;
  --dark-light-rgb: 41, 52, 61;
  --light-gray-rgb: 244, 247, 251;
  --purple-rgb: 111, 66, 193;
  --cyan-rgb: 83, 155, 255;
  --primary-text-emphasis: shade-color(#635bff, 60%);
  --secondary-text-emphasis: shade-color(#16CDC7, 60%);
  --success-text-emphasis: shade-color(#36c76c, 60%);
  --info-text-emphasis: shade-color(#46caeb, 60%);
  --warning-text-emphasis: shade-color(#F8C20A, 60%);
  --danger-text-emphasis: shade-color(#ff6692, 60%);
  --light-text-emphasis: #f6f7f9;
  --dark-text-emphasis: #29343d;
  --primary-bg-subtle: #DDDBFF;
  --secondary-bg-subtle: #A6F7F5;
  --success-bg-subtle: #EBFAF0;
  --info-bg-subtle: #d4eef5;
  --warning-bg-subtle: #FFF9E5;
  --danger-bg-subtle: #FFCCDB;
  --error-bg-subtle: #FFCCDB;
  --light-bg-subtle: #f6f7f9;
  --dark-bg-subtle: #29343d;
  --primary-border-subtle: #dad8fd;
  --secondary-border-subtle: #a2ebe9;
  --success-border-subtle: #afe9c4;
  --info-border-subtle: #b5eaf7;
  --warning-border-subtle: #fce79d;
  --danger-border-subtle: #ffc2d3;
  --light-border-subtle: #eff4fa;
  --dark-border-subtle: #5a6a85;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --font-sans-serif: "Manrope", sans-serif;
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --body-font-family: var(--font-sans-serif);
  --body-font-size: 0.875rem;
  --body-font-weight: 400;
  --body-line-height: 1.5;
  --body-color: #98A4AE;
  --body-color-rgb: 152, 164, 174;
  --body-bg: #fff;
  --body-bg-rgb: 255, 255, 255;
  --emphasis-color: #000;
  --emphasis-color-rgb: 0, 0, 0;
  --secondary-color: rgba(152, 164, 174, 0.75);
  --secondary-color-rgb: 152, 164, 174;
  --secondary-bg: #eff4fa;
  --secondary-bg-rgb: 239, 244, 250;
  --tertiary-color: rgba(152, 164, 174, 0.5);
  --tertiary-color-rgb: 152, 164, 174;
  --tertiary-bg: #f6f7f9;
  --tertiary-bg-rgb: 246, 247, 249;
  --heading-color: #29343d;
  --link-color: #29343d;
  --link-color-rgb: 41, 52, 61;
  --link-decoration: underline;
  --link-hover-color: #635bff;
  --link-hover-color-rgb: 99, 91, 255;
  --code-color: #d63384;
  --highlight-color: #98A4AE;
  --highlight-bg: #fef3ce;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #e0e6eb;
  --border-color-translucent: rgba(0, 0, 0, 0.175);
  --border-radius: 12px;
  --border-radius-sm: 8px;
  --border-radius-lg: 15px;
  --border-radius-xl: 18px;
  --border-radius-xxl: 2rem;
  --border-radius-2xl: var(--border-radius-xxl);
  --border-radius-pill: 50rem;
  --box-shadow: 0px 1px 4px 0px rgba(133, 146, 173, 0.2);
  --box-shadow-sm: 0px 2px 4px -1px rgba(175, 182, 201, 0.2);
  --box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --box-shadow-inset: unset;
  --focus-ring-width: 0.25rem;
  --focus-ring-opacity: 0.25;
  --focus-ring-color: rgba(99, 91, 255, 0.25);
  --form-valid-color: #36c76c;
  --form-valid-border-color: #36c76c;
  --form-invalid-color: #ff6692;
  --form-invalid-border-color: #ff6692;
  --disabled-looking-opacity: 0.6;
  --disabled-looking-bg: #f6f7f9;
}