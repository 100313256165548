.help-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        row-gap: 8px;
}

.help-section svg {
        width: 20px;
        height: 20px;

        margin-bottom: 12px;
}

.help-section h5 {
        font-size: 14px;
        font-weight: bold;
        color: #4a4a4a;

        margin: 0;
}

.help-section p {
        font-size: 14px;
        font-weight: 200;
        color: #4a4a4a;

        margin: 0;
        margin-bottom: 12px !important;
}