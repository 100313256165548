.summary {
  background: white;
  width: 450px;

  padding: 45px 30px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summary .list-unstyled {
  max-height: 500px;
  overflow-y: auto;
}

.summary h6 {
        margin: 0;
}
