.calculation-item {
  padding: 24px 12px;
}

.calculation-header {
  padding: 16px 24px;
  background-color: var(--light-gray);

  border-radius: var(--border-radius);

  color: #000;

  font-size: 14px;
  font-weight: bold;

  display: flex;

  flex-direction: row;
  justify-content: space-between;
}

.calculation-header__label {
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}

.calculation-header__label .step {
  background-color: var(--primary-bg-subtle);
  color: var(--primary);
  padding: 4px 8px;
  border-radius: 4px;

  font-weight: bold;
  font-size: 12px;
}



.calculation-item__info {
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 12px;
}

.calculation-item__label svg {
  margin-right: 8px;
}

.info-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
}

.calculation-icon {
  background-color: var(--primary-bg-subtle);
  color: var(--primary);
  padding: 4px 6px;
  border-radius: 4px;
}
