.configuration-header {
        --header-spacer-y: 1rem;
        --header-spacer-x: 1rem;
        --header-border-radius: 12px;
        --header-background-color: white;

        width: 100%;

        text-transform: capitalize;
        
        background-color: var(--header-background-color);
        border-radius: var(--header-border-radius);

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: var(--header-spacer-x) var(--header-spacer-y);
}

.configuration-header h4 {
        margin-bottom: 0 !important;
        font-size: 18px;
}