.table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

table {
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
  table-layout: fixed; /* Enables fixed column widths */
}

thead {
  background-color: var(--primary-border-subtle);
  color: var(--primary);
  position: sticky;
}

tbody {
  overflow-y: auto;
}

th {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  text-transform: uppercase;
}

th:first-child {
  width: fit-content;
  padding: 4px;
}

tbody tr {
  background-color: white;
  transition: background-color 0.3s ease;
}

tbody tr td:first-child {
  color: var(--primary) !important;
}

tbody td {
  padding: 24px 12px;
  border-bottom: 1px solid #eee;
  text-overflow: ellipsis; /* Handles overflow */
  overflow: hidden;
  white-space: nowrap;
}

/* Set fixed width for specific columns */
th:nth-child(1),
td:nth-child(1) {
  width: 10%; /* First column takes 10% of table width */
}

th:nth-child(2),
td:nth-child(2),
th:nth-child(3),
td:nth-child(3),
th:nth-child(4),
td:nth-child(4) {
  width: calc(100% - 91px - 10%); /* Second column takes 30% of table width */
}

th:nth-child(5),
td:nth-child(5) {
  width: 91px; /* Second column takes 30% of table width */
}

td:nth-child(5).remove {
  /* width: 100%; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

td:nth-child(5).remove div {
  background-color: var(--error-bg-subtle);
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--error);

  transition: background-color 0.3s ease;
}

td:nth-child(5).remove div:hover {
  background-color: var(--error);
  transition: background-color 0.3s ease;
  color: var(--error-bg-subtle);
  cursor: pointer;
}

td:nth-child(5).remove.disabled div, td:nth-child(5).remove.disabled div:hover {
        cursor: not-allowed;
        background-color: var(--disabled-looking-bg) !important;
        opacity: var(--disabled-looking-opacity);
        color: var(--gray-500);
}



tbody tr td select {
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 16px;
  cursor: pointer;
}

td select:focus,
td input:focus,
td select:hover,
td input:hover {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
  outline: none;
}

td .unit {
  z-index: 0 !important;
  position: absolute;

  font-size: 12px;

  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

tbody tr:hover {
  background-color: #f9f9f9;
}

.relative {
  position: relative;
}

tbody tr td {
  padding: 12px 24px;
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #eee;
  text-align: center;
}

tbody tr td:first-child {
  font-weight: bold;
}

tbody tr:last-child td {
  border-bottom: none;
}

.table-container table {
  border-radius: 8px;
  overflow: hidden;
}

.add-row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  margin-top: 2px;
  margin-bottom: 24px;
}

.add-row {
  cursor: pointer;
  --add-row-background-color: var(--primary-border-subtle);
}

.add-row .add-row-icon {
  background-color: var(--add-row-background-color);
  color: white;
  border: none;
  width: 40px;
  height: 20px;
  font-size: 10px;
  border-radius: 16px;
  cursor: pointer;
  padding: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.3s ease;
}

.add-row .line {
  height: 1px;
  width: 100%;

  background-color: var(--add-row-background-color);
  transition: background-color 0.3s ease;
}

.add-row:hover {
  --add-row-background-color: var(--primary);
  transition: background-color 0.3s ease;
}

td input,
td select {
  height: 43px;
  width: 100%;
  padding-left: 54px;
  font-size: 0.875rem;
  margin-top: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--heading-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-clip: padding-box;
  border: var(--border-width) solid var(--border-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

td .relative {
  z-index: 0;
}

.space-input-field .unit {
  position: absolute;

  font-size: 12px;

  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: -1;
}

td .icon,
td .unit {
  color: var(--primary);
}
