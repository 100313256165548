.configuration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.configuration .content {
  padding: 30px;
  height: 100%;
}

.configuration .content .configuration-item {
        overflow-y: auto;
        max-height: calc(100vh - 307px);
}


.configuration .content > h4 {
  margin-bottom: 24px;
}

input::placeholder {
        color: #c6c6c6;
}


.configuration-item {
  width: 100%;
  height: calc(100% - 56px - 30px);
  margin-top: 30px;

  background-color: var(--body-bg);
  border-radius: var(--border-radius);
}

.configuration-item .form-group {
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  padding: 24px;

  column-gap: 72px;
}

.configuration-item .form-group input, .form-group select {
  display: block;
  width: 300px;
  padding: 10px 16px;
  padding-left: 54px;
  font-size: 0.875rem;
  margin-top: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--heading-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-clip: padding-box;
  border: var(--border-width) solid var(--border-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.configuration-item .form-group select {
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 16px;
  padding-right: 40px;
  cursor: pointer;
}

.form-group-label {
  display: inline-block;
  font-weight: 600;
  color: #29343d;

  font-size: 16px;
}

.form-group-label svg {
  color: var(--text-primary);
}

.form-group-item {
  position: relative;
  z-index: 0;

  height: fit-content;
}

.form-group-item p {
  font-size: 13px;
  line-height: 24px;

  margin: 0;
  margin-top: 8px;
  color: var(--text-muted);

  max-width: 350px;
}

.form-group-item small {
  margin-top: 12px !important;
  color: var(--text-muted);
}

.icon {
  position: absolute;

  width: 43px;
  height: 43px;
  background: var(--primary-bg-subtle);
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  z-index: -1;
}

.unit {
  position: absolute;

  font-size: 12px;

  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: -1;
}

.unit.r--4 {
  right: 4px !important;
}

.unit .super-script {
  position: absolute;
  right: 4px;
  top: 10px;
}

.configuration-item input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.icon-smaller {
  font-size: 14px !important;
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.error {
  --border-color: var(--error);
  --heading-color: var(--error);
  --primary-bg-subtle: var(--error-bg-subtle);
  --primary: var(--error) !important;
}

.configuration-item .form-group input:focus, .form-group select:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
  outline: none;
}

.configuration-item .form-group input:focus.error, .form-group select:focus.error {
  border-color: var(--error);
  box-shadow: 0 0 0 0.2rem rgba(var(--error-rgb), 0.25);
  outline: none;
}

p.error {
  font-size: 14px;
  color: var(--error);
}

.decrease-top-padding {
  padding-top: 12px !important;
}

.decrease-bottom-padding {
  padding-bottom: 12px !important;
}

.w-200 {
  width: 200px !important;
}