.body {
        background: #f4f7fb;
        width: 100vw;
        height: calc(100vh - 72px);

        display: flex;
        flex-direction: row;
        justify-content: space-between;


        overflow-y: hidden;
}